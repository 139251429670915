import * as React from "react";
import Layout from "../components/layout";

const NewZealandPage = () => {
  return (
    <Layout pageTitle="New Zealand">
      <p>IPA New Zealand website:</p>
      <p>
        <a
          style={{ color: "#4a71b6", textDecoration: "none" }}
          href="https://www.ipa.org.nz/"
        >
          https://www.ipa.org.nz/
        </a>
      </p>
      <p>NZ Police Association website:</p>
      <p>
        <a
          style={{ color: "#4a71b6", textDecoration: "none" }}
          href="http://www.policeassn.org.nz/"
        >
          http://www.policeassn.org.nz/
        </a>
      </p>
    </Layout>
  );
};

export default NewZealandPage;
